import { makeAutoObservable, set } from 'mobx';
export class UserStore {
  isAuthorized = Boolean(window.localStorage.getItem('miura_access_token'));
  isChallenge = false;

  refresh_token = window.localStorage.getItem('miura_refresh_token') || null;
  access_token = window.localStorage.getItem('miura_access_token') || null;

  user = {};

  constructor(root) {
    this.root = root;
    makeAutoObservable(this, { root: false });
  }

  *login({ email, password }) {
    const { data } = yield this.root.api.login({ username: email, password: password });

    this.refresh_token = data.refresh;
    this.access_token = data.access;

    window.localStorage.setItem('miura_refresh_token', this.refresh_token);
    window.localStorage.setItem('miura_access_token', this.access_token);

    if (this.access_token) {
      this.isAuthorized = true;
    }
  }

  *refreshToken() {
    const response = yield this.root.api.refreshToken({ refresh: this.refresh_token });
    const { data = {} } = response;

    console.log('Refresh token data: ' + JSON.stringify(response));

    this.refresh_token = data.refresh;
    this.access_token = data.access;

    window.localStorage.setItem('miura_refresh_token', this.refresh_token);
    window.localStorage.setItem('miura_access_token', this.access_token);

    return response;
  }

  *getActiveUser() {
    const { data } = yield this.root.api.getActiveUser();
    set(this.user, data);
  }

  *logout() {
    try {
      yield this.root.api.logout({ access_token: this.access_token });
    } catch (error) {
      console.error(error);
    } finally {
      this.removeAuthInfo();
    }
  }

  removeAuthInfo() {
    this.isAuthorized = false;
    window.localStorage.clear();
  }

  get fullName() {
    if (this.user.first_name || this.user.last_name) {
      return `${this.user.first_name && this.user.first_name} ${
        this.user.last_name && this.user.last_name
      }`.trim();
    }
    return this.user.email;
  }

  // TODO - Not yet implemented - Start

  *forgotPassword({ email }) {
    yield this.root.api.forgotPassword({ email });
  }

  *confirmForgotPassword({ email, confirmation_code, password, password2 }) {
    yield this.root.api.confirmForgotPassword({ email, confirmation_code, password, password2 });
  }

  *resendConfirmationToken({ email }) {
    yield this.root.api.resendConfirmationToken({ email });
  }

  // TODO - Not yet implemented - End
}
