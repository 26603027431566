import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Auth from 'routes/Auth';
import BusinessDetail from './Businesses/BusinessDetail';
import BusinessFunctionDetail from './BusinessFunctions/BusinessFunctionDetail';
import BusinessFunctions from './BusinessFunctions';
import BusinessProcessDetail from 'routes/BusinessProcesses/BusinessProcessDetail';
import BusinessProcesses from './BusinessProcesses';
import Businesses from './Businesses';
import RegulationDocuments from './RegulationDocuments'
import MicroRegulatoryProcessesList from './RegulationDocuments/MicroRegulatoryProcessesList';
import MicroRegulatoryProcessesDetail from 'routes/RegulationDocuments/MicroRegulatoryProcessesList/MicroRegulatoryDetail';
import UserMicroProcessManagementDashboard from './UserMicroProcessManagementDashboard';
import UserMicroProcessManagementDashboardDetail from 'routes/UserMicroProcessManagementDashboard/MicroRegulatoryDetail';
import EvidenceDocumentDashboard from './EvidenceDocumentDashboard';
// import EvidenceDocumentDashboardDetail from 'routes/EvidenceDocumentDashboardDashboard/EvidenceDocumentDashboardetail';
import Dashboard from 'routes/Dashboard';
import HorizonScanning from './HorizonScanning';
import ImpactAssessment from 'routes/ImpactAssessment';
import RegChangeManagement from 'routes/RegChangeManagement';
import IssueManagementDashboard from 'routes/IssueManagementDashboard';
import MicroRegulatoryProcess from 'routes/MicroRegulatoryProcess';
import PrivateLayout from 'layouts/PrivateLayout';
import PrivateRoute from 'shared/PrivateRoute';
import PublicLayout from 'layouts/PublicLayout';
import PublicRoute from 'shared/PublicRoute';
import Root from 'routes/Root';
import { observer } from 'mobx-react-lite';
import Evidence from "./MicroRegulatoryProcess/Evidence";

function Routes() {
  return (
    <Switch>
      <PublicRoute path="/auth" component={Auth} layout={PublicLayout} />

      <PrivateRoute path="/" component={Root}>
        <PrivateLayout>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/dashboard" render={() => <Dashboard />} />
            <Route exact path="/business" render={() => <BusinessDetail />} />
            <Route exact path="/businesses" render={() => <Businesses />} />
            <Route exact path="/business-functions" render={() => <BusinessFunctions />} />
            <Route exact path="/business-function" render={() => <BusinessFunctionDetail />} />
            <Route exact path="/business-processes" render={() => <BusinessProcesses />} />
            <Route exact path="/business-process" render={() => <BusinessProcessDetail />} />
            <Route
              exact
              path="/micro-regulatory-process"
              render={() => <MicroRegulatoryProcess />}
            />
            <Route exact path="/horizon-scanning" render={() => <HorizonScanning />} />
            <Route exact path="/user-micro-regulatory-list" render={() => <UserMicroProcessManagementDashboard />} />
            <Route exact path="/evident-micro-regulatory-list" render={() => <EvidenceDocumentDashboard/>} />
            <Route exact path="/user-micro-regulatory-process-detail" render={() => <UserMicroProcessManagementDashboardDetail />} />
            <Route exact path="/micro-regulatory-list" render={() => <MicroRegulatoryProcessesList />} />
            <Route exact path="/micro-regulatory-process-detail" render={() => <MicroRegulatoryProcessesDetail />} />
            <Route exact path="/impact-assessment" render={() => <ImpactAssessment />} />
            <Route exact path="/reg-change-management" render={() => <RegChangeManagement />} />
            <Route exact path="/regulation-documents" render={() => <RegulationDocuments />} />
            <Route exact path="/issue-management" render={() => <IssueManagementDashboard />} />

          </Switch>
        </PrivateLayout>
      </PrivateRoute>
    </Switch>
  );
}

export default observer(Routes);
