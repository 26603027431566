import 'assets/styles/compliance.scss';

import * as api from 'config/endpoints';

import React, { useEffect, useState } from 'react';

import Control from 'routes/MicroRegulatoryProcess/Control';
import Evidence from 'routes/MicroRegulatoryProcess/Evidence';
import MicroRegulatoryProcessNav from 'shared/MicroRegulatoryProcessNav';
import Moment from 'moment';
import Monitoring from 'routes/MicroRegulatoryProcess/Monitoring';
import Policy from 'routes/MicroRegulatoryProcess/Policy';
import Procedure from 'routes/MicroRegulatoryProcess/Procedure';
import Risk from 'routes/MicroRegulatoryProcess/Risk';
import { getData } from 'services/api-utils';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStore } from 'hooks';

const MicroRegulatoryProcess = () => {
  const [activeBusinessId, setActiveBusinessId] = useState('');
  const [activeBusinessFunctionId, setActiveBusinessFunctionId] = useState('');
  const [activeBusinessProcessId, setActiveBusinessProcessId] = useState('');
  const [activeMicroRegulatoryProcessId, setActiveMicroRegulatoryProcessId] = useState('');
  const [activeTab, setActiveTab] = useState('Policy');
  const [microRegulatoryProcessData, setMicroRegulatoryProcessData] = useState();
  const [sectionData, setSectionData] = useState();
  const [validationLogsData, setValidationLogsData] = useState();
  const store = useStore();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const resetState = () => {
    setMicroRegulatoryProcessData();
    setSectionData();
  };

  useEffect(() => {
    const params = window.location.href;
    let url = new URL(params);
    let activeBusinessId = url.searchParams.get('business');
    let activeBusinessFunctionId = url.searchParams.get('business_function');
    let activeBusinessProcessId = url.searchParams.get('business_process');
    let activeMicroRegulatoryProcessId = url.searchParams.get('micro_process');
    let activeTab = url.searchParams.get('tab');
    setActiveBusinessId(activeBusinessId);
    setActiveBusinessFunctionId(activeBusinessFunctionId);
    setActiveBusinessProcessId(activeBusinessProcessId);
    setActiveMicroRegulatoryProcessId(activeMicroRegulatoryProcessId);
    setActiveTab(activeTab);
  }, []);

  useEffect(() => {
    const microRegulatoryProcessData =
      activeMicroRegulatoryProcessId !== '' ? getMicroRegulatoryProcessData() : {};
    const sectionData = activeMicroRegulatoryProcessId !== '' ? getSectionData() : {};
  }, [activeMicroRegulatoryProcessId, activeTab]);

  const getMicroRegulatoryProcessData = async () => {
    const { data } = await store.api.getMicroProcess(activeMicroRegulatoryProcessId);
    setMicroRegulatoryProcessData(data);

    let getCategoryValidationLogsEndpoint = `${api.COMPLIANCE_DOCUMENT_VALIDATION_LOGS}?compliance_document_type=${activeTab}&company_compliance_category=${activeMicroRegulatoryProcessId}&page_size=10`;

    if (activeTab === 'control' || activeTab === 'monitoring') {
      getCategoryValidationLogsEndpoint = `${api.COMPLIANCE_DOCUMENT_VALIDATION_LOGS}?company_compliance_category=${activeMicroRegulatoryProcessId}&page_size=5`;
    }

    const getCategoryValidationLogsData = await getData(getCategoryValidationLogsEndpoint);
    setValidationLogsData(getCategoryValidationLogsData);
  };

  const getSectionData = async (shouldNotResetState) => {
    !shouldNotResetState && resetState();
    let sectionEndpoint = '';
    if (activeTab === 'Risk') {
      sectionEndpoint = api.RISKS_API_ENDPOINT;
    }
    if (activeTab === 'Policy') {
      sectionEndpoint = api.POLICIES_API_ENDPOINT;
    }
    if (activeTab === 'Procedure') {
      sectionEndpoint = api.PROCEDURES_API_ENDPOINT;
    }
    if (activeTab === 'Evidence') {
      sectionEndpoint = api.EVIDENCES_API_ENDPOINT;
    }
    if (activeTab === 'Control') {
      sectionEndpoint = api.CONTROLS_API_ENDPOINT;
    }
    if (activeTab === 'Monitoring') {
      sectionEndpoint = api.MONITORINGS_API_ENDPOINT;
    }
    const getSectionDataEndpoint = `${sectionEndpoint}${activeMicroRegulatoryProcessId}/`;
    const getSectionData = await getData(getSectionDataEndpoint);
    setSectionData(getSectionData);
  };

  const updateTab = (tabName) => {
    setActiveTab(tabName);
    history.replace(
      window.location.pathname +
        '?business=' +
        activeBusinessId +
        '&business_function=' +
        activeBusinessFunctionId +
        '&business_process=' +
        activeBusinessProcessId +
        '&micro_process=' +
        activeMicroRegulatoryProcessId +
        '&tab=' +
        tabName,
    );
  };

  const triggerReloadContent = () => {
    getMicroRegulatoryProcessData();
    getSectionData();
  };

  const triggerReloadContentNoReset = () => {
    getSectionData(true);
  };

  return (
    <section className={'section-container'}>
     <div className="back-nav" onClick={() => history.push('/micro-regulatory-list')}>
        Compliance Micro-Process Library
      </div>

      <h4 className="title-spacing">
        {microRegulatoryProcessData ? microRegulatoryProcessData.micro_regulatory_process.name.slice(0,60).concat(' ...') : ''}
      </h4>
      <div className="owner-text">
        Owner:{' '}
        {microRegulatoryProcessData && (
          <span style={{ color: '#818181' }}>
            {microRegulatoryProcessData.owner.first_name}{' '}
            {microRegulatoryProcessData.owner.last_name}
          </span>
        )}
        <br />
        Last updated:{' '}
        {microRegulatoryProcessData && (
          <span style={{ color: '#818181' }}>
            {Moment(microRegulatoryProcessData.last_updated).format('MMM Do YYYY, h:mm a')}
          </span>
        )}
      </div>
      <MicroRegulatoryProcessNav
        activeTab={activeTab}
        updateTab={(tabName) => updateTab(tabName)}
      />

      {microRegulatoryProcessData === undefined || sectionData === undefined ? (
        <></>
      ) : (
        <>
          {activeTab === 'Risk' && (
            <Risk
              microRegulatoryProcessData={microRegulatoryProcessData}
              sectionData={sectionData}
              validationLogsData={validationLogsData}
              triggerReloadContent={() => triggerReloadContent()}
            />
          )}
          {activeTab === 'Policy' && (
            <Policy
              microRegulatoryProcessData={microRegulatoryProcessData}
              sectionData={sectionData}
              validationLogsData={validationLogsData}
              triggerReloadContent={() => triggerReloadContent()}
            />
          )}
          {activeTab === 'Procedure' && (
            <Procedure
              microRegulatoryProcessData={microRegulatoryProcessData}
              sectionData={sectionData}
              validationLogsData={validationLogsData}
              triggerReloadContent={() => triggerReloadContent()}
            />
          )}
          {activeTab === 'Evidence' && (
            <Evidence
              microRegulatoryProcessData={microRegulatoryProcessData}
              sectionData={sectionData}
              validationLogsData={validationLogsData}
              triggerReloadContent={() => triggerReloadContent()}
            />
          )}
          {activeTab === 'Control' && (
            <Control
              microRegulatoryProcessData={microRegulatoryProcessData}
              sectionData={sectionData}
              validationLogsData={validationLogsData}
              triggerReloadContent={() => triggerReloadContent()}
              triggerReloadContentNoReset={() => triggerReloadContentNoReset()}
            />
          )}
          {activeTab === 'Monitoring' && (
            <Monitoring
              microRegulatoryProcessData={microRegulatoryProcessData}
              sectionData={sectionData}
              validationLogsData={validationLogsData}
              triggerReloadContent={() => triggerReloadContent()}
              triggerReloadContentNoReset={() => triggerReloadContentNoReset()}
            />
          )}
        </>
      )}
    </section>
  );
};

MicroRegulatoryProcess.defaultProps = {};

export default MicroRegulatoryProcess;
