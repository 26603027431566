import React, { useCallback, useEffect, useState } from 'react';
import { Container, Typography, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useStore } from 'hooks';

import Field from 'shared/Field';

const useStyles = makeStyles(() => ({
  root: {
    alignSelf: 'center',
    margin: 'auto 0',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  alert: {
    marginLeft: '32px',
  },
}));

const schema = joi.object({
  email: joi
    .string()
    .email({ tlds: { allow: false } })
    .trim()
    .required()
    .messages({
      'string.empty': 'Email is not allowed to be empty',
      'string.email': 'Please enter a valid email address',
    }),
});

function ForgotPassword() {
  const classes = useStyles();

  const store = useStore();
  const history = useHistory();

  const [errors, setErrors] = useState(null);
  const { handleSubmit, control, setError, formState } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (!formState.errors.email) {
      setErrors(null);
      return;
    }
    if (formState.errors.email) {
      setErrors(formState.errors.email.message);
    }
  }, [formState]);

  const onSubmit = useCallback(
    async credentials => {
      try {
        await store.user.forgotPassword(credentials);
        history.push('/auth/reset-password');
      } catch (err) {
        const errors = Object.keys(err.response.data).map(item => ({
          type: 'server',
          name: item,
          message: err.response.data[item][0],
        }));

        errors.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    },
    [setError],
  );

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h1" align="center">
        Forgot Password
      </Typography>
      <Typography variant="h5" align="center">
        Enter your email and we will send you a confirmation code
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid container spacing={2} direction="column" alignItems="stretch">
          {errors && (
            <Grid item>
              <Alert className={classes.alert} severity="error">
                {errors}
              </Alert>
            </Grid>
          )}
          <Grid item>
            <Field icon={MailOutlineIcon}>
              <Controller
                id="email"
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={Boolean(fieldState.error)}
                    fullWidth
                    placeholder="Enter email address"
                  />
                )}
              />
            </Field>
          </Grid>
          <Grid container item justify="center">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default observer(ForgotPassword);
