import { Button, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import Field from 'shared/Field';
import LinkButton from 'shared/LinkButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

const schema = joi.object({
  email: joi
    .string()
    .email({ tlds: { allow: false } })
    .required()
    .trim()
    .messages({
      'string.empty': 'Email is not allowed to be empty',
      'string.email': 'Please enter a valid email address',
    }),
  password: joi.string().min(5).max(16).required().messages({
    'string.empty': 'Password is not allowed to be empty',
    'string.min': 'Password length must be at least 5 characters long',
    'string.max': 'Password length must be less than or equal to 16 characters long',
  }),
});

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form_container: {
    paddingTop: '20px',
    width: '500px',
  },
  text_field: {
    width: '450px',
  },
  alert: {
    marginLeft: '32px',
  },
}));

const SignInForm = ({ onSubmit }) => {
  const classes = useStyles();

  const history = useHistory();
  const [error, setError] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control, formState } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (!formState.errors.email && !formState.errors.password) {
      setError(null);
      return;
    }
    const errorAccumulator = [];
    if (formState.errors.email) {
      errorAccumulator.push(formState.errors.email.message);
    }
    if (formState.errors.password) {
      errorAccumulator.push(formState.errors.password.message);
    }
    setError(errorAccumulator.join(', '));
  }, [formState]);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleForgotPassword = useCallback(() => {
    history.push('/auth/forgot-password');
  }, [history]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.form_container}
      >
        {error && (
          <Grid item>
            <Alert className={classes.alert} severity="error">
              {error}
            </Alert>
          </Grid>
        )}
        <Grid item>
          <Field icon={MailOutlineIcon}>
            <Controller
              id="email"
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  placeholder="Email"
                  error={Boolean(fieldState.error)}
                  className={classes.text_field}
                />
              )}
            />
          </Field>
        </Grid>

        <Grid item>
          <Field icon={VpnKeyIcon}>
            <Controller
              id="password"
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  error={Boolean(fieldState.error)}
                  fullWidth
                  className={classes.text_field}
                  placeholder="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Field>
        </Grid>

        {/* <Grid container item justify="flex-end">
          <LinkButton disableRipple disableFocusRipple onClick={handleForgotPassword}>
            Forgot Password
          </LinkButton>
        </Grid> */}

        <Grid container item justify="center">
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(SignInForm);
