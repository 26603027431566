import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import _isEmpty from 'lodash.isempty';
import { useStore } from 'hooks';

import Field from 'shared/Field';

const useStyles = makeStyles(() => ({
  root: {
    alignSelf: 'center',
    margin: 'auto 0',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  alert: {
    marginLeft: '32px',
  },
}));

const schema = joi.object({
  email: joi
    .string()
    .email({ tlds: { allow: false } })
    .required()
    .trim()
    .messages({
      'string.empty': 'Email is not allowed to be empty',
      'string.email': 'Please enter a valid email address',
    }),
  confirmation_code: joi.string().required().messages({
    'string.empty': 'Please fill Token field',
  }),
  password: joi.string().min(5).max(16).required(),
  password2: joi.any().valid(joi.ref('password')).required().messages({
    'any.only': 'Verifying password must be as password',
  }),
});

function ResetPassword() {
  const classes = useStyles();

  const store = useStore();
  const history = useHistory();

  const [errors, setErrors] = useState(null);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [password2Visibility, setPassword2Visibility] = useState(false);
  const { handleSubmit, control, setError, formState } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      email: '',
      confirmation_code: '',
      password: '',
      password2: '',
    },
  });

  useEffect(() => {
    if (_isEmpty(formState.errors)) {
      setErrors(null);
      return;
    }
    const errorAccumulator = [];
    if (formState.errors.email) {
      errorAccumulator.push(formState.errors.email.message);
    }
    if (formState.errors.confirmation_code) {
      errorAccumulator.push(formState.errors.confirmation_code.message);
    }
    if (formState.errors.password) {
      errorAccumulator.push(formState.errors.password.message);
    }
    if (formState.errors.password2) {
      errorAccumulator.push(formState.errors.password2.message);
    }
    setErrors(errorAccumulator.join(', '));
  }, [formState]);

  const onSubmit = useCallback(
    async credentials => {
      try {
        await store.user.confirmForgotPassword(credentials);
        history.push('/auth');
      } catch (err) {
        const errors = Object.keys(err.response.data).map(item => ({
          type: 'server',
          name: item,
          message: err.response.data[item][0],
        }));

        errors.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    },
    [setError],
  );

  const handleClickShowPassword = useCallback(() => {
    setPasswordVisibility(!passwordVisibility);
  }, [passwordVisibility]);

  const handleClickShowPassword2 = useCallback(() => {
    setPassword2Visibility(!password2Visibility);
  }, [password2Visibility]);

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h1" align="center">
        Reset Password
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid container spacing={2} direction="column" alignItems="stretch">
          {errors && (
            <Grid item>
              <Alert className={classes.alert} severity="error">
                {errors}
              </Alert>
            </Grid>
          )}
          <Grid item>
            <Field icon={MailOutlineIcon}>
              <Controller
                id="email"
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={Boolean(fieldState.error)}
                    fullWidth
                    placeholder="Enter email address"
                  />
                )}
              />
            </Field>
          </Grid>

          <Grid item>
            <Field icon={VerifiedUserIcon}>
              <Controller
                id="confirmation_code"
                name="confirmation_code"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={Boolean(fieldState.error)}
                    fullWidth
                    placeholder="Enter token"
                    autoComplete="off"
                  />
                )}
              />
            </Field>
          </Grid>

          <Grid item>
            <Field icon={VpnKeyIcon}>
              <Controller
                id="password"
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    type={passwordVisibility ? 'text' : 'password'}
                    error={Boolean(fieldState.error)}
                    fullWidth
                    placeholder="Enter New Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('password')}
                          >
                            {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Field>
          </Grid>

          <Grid item>
            <Field icon={VpnKeyIcon}>
              <Controller
                id="password2"
                name="password2"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    type={password2Visibility ? 'text' : 'password'}
                    error={Boolean(fieldState.error)}
                    fullWidth
                    placeholder="Verify New Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                          >
                            {password2Visibility ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Field>
          </Grid>

          <Grid container item justify="center">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default observer(ResetPassword);
