import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useCallback } from 'react';

import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BusinessIcon from '@material-ui/icons/Business';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import ReplyIcon from '@material-ui/icons/Reply';
import Evidence from '@material-ui/icons/AccountBalance';
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircle';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useStore } from 'hooks';

const frameworkMenuItems = [
  {
    label: 'User Micro-process Management Dashboard',
    icon: <DashboardIcon style={{ color: '#fff' }} />,
    path: '/user-micro-regulatory-list',
  },
  {
    label: 'Business Model',
    icon: <BusinessIcon style={{ color: '#fff' }} />,
    path: '/businesses',
  },
  {
    label: 'Evidence Document Dashboard',
    icon: <Evidence style={{ color: '#fff' }} />,
    path: '/evident-micro-regulatory-list',
  },
  {
    label: 'Horizon Scanning',
    icon: <SwapHorizontalCircleOutlinedIcon style={{ color: '#fff' }} />,
    path: '/horizon-scanning',
  },
  {
    label: 'Impact Assessment',
    icon: <SwapHorizontalCircleOutlinedIcon style={{ color: '#fff' }} />,
    path: '/impact-assessment',
  },
  {
    label: 'Regulation Management Dashboard',
    icon: <SwapHorizontalCircleOutlinedIcon style={{ color: '#fff' }} />,
    path: '/reg-change-management',
  },
  {
    label: 'Regulation Documents',
    icon: <BusinessIcon style={{ color: '#fff' }} />,
    path: '/regulation-documents',
  },
  {
    label: 'Issue Management Dashboard',
    icon: <AnnouncementOutlinedIcon style={{ color: '#fff' }} />,
    path: '/issue-management',
  },
];

function Menu() {
  const history = useHistory();
  const store = useStore();
  const classes = useStyles();
  const [frameworkSubNavOpen, setFrameworkSubNavOpen] = React.useState(true);

  const redirectTo = useCallback(
    (path) => () => {
      history.push(path);
    },
    [],
  );

  const handleLogout = useCallback(() => {
    store.user.logout();
  }, []);

  const handleFrameworkSubNavClick = () => {
    setFrameworkSubNavOpen(!frameworkSubNavOpen);
  };

  return (
    <>
      <List component="nav">
        <ListItem button onClick={redirectTo('/dashboard')}>
          <ListItemIcon>
            <HomeIcon style={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText primary="Control Centre" />
        </ListItem>
      </List>

      <Divider className={classes.divider} />

      <List component="nav">
        <ListItem button onClick={handleFrameworkSubNavClick}>
          <ListItemIcon>
            <AssignmentTurnedInIcon style={{ color: '#fff' }} />
          </ListItemIcon>
          <ListItemText primary="Framework" />
          {frameworkSubNavOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={frameworkSubNavOpen} timeout="auto" unmountOnExit>
          <List>
            {frameworkMenuItems.map((item) => (
              <ListItem key={item.path} button onClick={redirectTo(item.path)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ReplyIcon style={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: '#ffffff',
  },
}));

export default Menu;
