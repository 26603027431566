import { breakpoints } from 'theme/breakpoints';
import { createMuiTheme } from '@material-ui/core';
import { mixins } from 'theme/mixins';
import { palette } from 'theme/palette';
import { props } from 'theme/props';
import { typography } from 'theme/typography';

const theme = createMuiTheme({
  typography,
  breakpoints,
  palette,
  props,
  mixins,
  zIndex: {
    appBar: 1000,
    drawer: 1100,
  },
});

export default theme;
